import { createApp } from "vue";
import VueSvgInlinePlugin from "vue-svg-inline-plugin";
import App from "./App.vue";
import store from "./store";

App.beforeCreate = function() {
  this.$store.dispatch("initialiseStore");
};

// Reload on version change
store.watch(
  state => state.version,
  (newVersion, oldVersion) => {
    if (oldVersion) {
      window.location.reload();
    }
  }
);

createApp(App)
  .use(VueSvgInlinePlugin)
  .use(store)
  .mixin({
    methods: {
      $t(key) {
        return this.$store.state.i18n[key];
      }
    }
  })
  .mount("#app");
