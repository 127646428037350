<template>
  <transition :css="false" @enter="animateEnter" @leave="animateLeave">
    <div
      v-if="$store.getters.appState === 'StateCountdown'"
      class="state state--StateCountdown"
    >
      <h1 ref="heading" class="heading">Estimated arrival time</h1>
      <div ref="countdown" class="countdown">{{ formattedCountdown }}</div>
    </div>
  </transition>
</template>

<script>
import gsap from "gsap";

export default {
  name: "StateCountdown",
  computed: {
    formattedCountdown() {
      return this.$store.getters.formattedCountdown;
    }
  },

  methods: {
    animateEnter(el, done) {
      gsap.fromTo(
        el,
        { opacity: 0 },
        {
          opacity: 1,
          duration: 0.5,
          delay: 0,
          ease: "power2.inOut",
          onComplete: done
        }
      );
    },

    /* prettier-ignore */
    animateLeave(el, done) {
      // can't use refs, because they are already gone
      // in the leave hook
      const heading = this.$el.querySelector('.heading');
      const countdown = this.$el.querySelector('.countdown');
      const tl = gsap.timeline({ onComplete: done, defaults: { ease: "power2.in" }});
      tl.to(el,        { duration: 1, opacity: 0 }, 0.3);
      tl.to(heading,   { duration: 0.5, opacity: 0 }, 0);
      tl.to(countdown, { duration: 0.5, opacity: 0, yPercent: 10, scale: 0.8 }, 0);
    }
  }
};
</script>

<style scoped>
@import url("../../assets/css/variables.css");

.state {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.heading {
  margin-top: 50px;
  margin-bottom: 0;
  font-size: 30px;
  white-space: nowrap;
}

.countdown {
  font-size: 200px;
  font-weight: bold;
}
</style>
