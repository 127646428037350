<template>
  <transition :css="false" @enter="animateEnter" @leave="animateLeave">
    <div
      v-if="$store.getters.appState === 'StateBoarding'"
      class="state state--StateBoarding"
    >
      <!-- <audio /> -->
      <img :src="gondola" ref="gondola" alt="Gondola" class="gondola" />
      <h1 ref="heading">Now boarding</h1>
      <div ref="countdown" class="countdown">{{ formattedCountdown }}</div>

      <div ref="arrowsLeft" class="arrows">
        <img v-svg-inline :src="arrow" alt="" class="arrow arrow--01" />
        <img v-svg-inline :src="arrow" alt="" class="arrow arrow--02" />
        <img v-svg-inline :src="arrow" alt="" class="arrow arrow--03" />
      </div>

      <div ref="arrowsRight" class="arrows arrows--left">
        <img v-svg-inline :src="arrow" alt="" class="arrow arrow--01" />
        <img v-svg-inline :src="arrow" alt="" class="arrow arrow--02" />
        <img v-svg-inline :src="arrow" alt="" class="arrow arrow--03" />
      </div>
    </div>
  </transition>
</template>

<script>
import gsap from "gsap";
import gondola from "@/assets/media/gondola.png";
import arrow from "@/assets/media/arrow.svg";

export default {
  name: "StateBoarding",
  data() {
    return { gondola, arrow };
  },
  computed: {
    formattedCountdown() {
      return this.$store.getters.formattedCountdown;
    }
  },
  methods: {
    /* prettier-ignore */
    animateEnter(el, done) {
      const tl = gsap.timeline({ onComplete: done, defaults: { ease: "power2.out" }});
      tl.from(el,                   { duration: 1, opacity: 0 }, 0.5);
      tl.from(this.$refs.gondola,   { duration: 1, scale: 0.9, yPercent: -10 }, 0.5);
      tl.from(this.$refs.countdown, { duration: 1, opacity: 0, yPercent: -30, scale: 1.5 }, 0.8);
      tl.from(this.$refs.heading,   { duration: 1, opacity: 0, yPercent: -20 }, 0.9);
    },

    animateLeave(el, done) {
      gsap.fromTo(
        el,
        { opacity: 1 },
        {
          opacity: 0,
          duration: 0.5,
          delay: 0,
          ease: "power2.inOut",
          onComplete: done
        }
      );
    }
  }
};
</script>

<style scoped>
@import url("../../assets/css/variables.css");

.state {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 800px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

h1 {
  margin-top: 50px;
  margin-bottom: 0;
  font-size: 80px;
}

.countdown {
  font-size: 80px;
  font-weight: bold;
  color: var(--color-highlight);
}

.gondola {
  width: 367px;
  max-height: 60vh;
  height: auto;
  display: block;
  object-fit: contain;
}

.arrows {
  position: absolute;
  top: 50%;
  right: -100px;
}
.arrows--left {
  transform: scaleX(-1);
  right: auto;
  left: -100px;
}

.arrow {
  width: 40px;
  margin: 0 10px;
  animation: arrow 2s infinite;
  stroke: var(--color);
}
.arrow--01 {
  animation-delay: 0.2s;
}
.arrow--02 {
  animation-delay: 0.1s;
}

@keyframes arrow {
  0% {
    stroke-width: 0;
    opacity: 1;
  }
  25% {
    stroke-width: 4;
  }
  50% {
    stroke-width: 4;
    opacity: 0;
  }
  100% {
    stroke-width: 0;
    opacity: 0;
  }
}
</style>
