<template>
  <div class="spinner" :class="`spinner--${appState}`">
    <div class="circle">
      <img :src="ovalLarge" alt="" />
    </div>
    <div class="circle circle--small">
      <img :src="ovalSmall" alt="" />
    </div>
  </div>
</template>

<script>
import ovalLarge from "@/assets/media/oval-large.png";
import ovalSmall from "@/assets/media/oval-small.png";

export default {
  name: "Spinner",
  data() {
    return { ovalLarge, ovalSmall };
  },
  props: {
    appState: String
  }
};
</script>

<style scoped>
@import url("../assets/css/variables.css");

.spinner {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) scale(0.5);
  transform-origin: center;
  width: 1482px;
  max-width: 90vw;
  transition: transform 1s ease-in-out;
}
.spinner::before {
  content: "";
  display: block;
  padding-bottom: 100%;
}

.spinner--StateBoarding {
  transform: translate(-50%, -50%) scale(1);
}

.circle {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
}

.circle img {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  animation: rotate 4s linear;
  animation-iteration-count: infinite;
  transform-origin: center;
  object-fit: contain;
  object-position: 50% 0;
}

.circle--small {
  transform: scale(0.93);
}
.circle--small img {
  object-position: 50% 100%;
}

@keyframes rotate {
  0% {
    transform: rotate(0);
  }

  100% {
    transform: rotate(-360deg);
  }
}
</style>
