<template>
  <Page />
</template>

<script>
import Page from "./views/Page.vue";

export default {
  name: "App",
  components: { Page }
};
</script>

<style>
@import url("https://fonts.googleapis.com/css?family=Roboto+Condensed:400,700&display=swap");
@import url("./assets/css/variables.css");

html {
  font-size: 87.5%;
}

body {
  font-family: "Roboto Condensed", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: var(--color);
  background: var(--color-background);
  margin: 0;
}
</style>
