import { createStore } from "vuex";
import VuexPersistence from "vuex-persist";
import {
  createClient,
  getConfig,
  getCountdown,
  getVersion
} from "../utils/api";

const { VUE_APP_CONTENTFUL_DEFAULT_LANGUAGE } = process.env;

const vuexLocal = new VuexPersistence({
  storage: window.localStorage,
  reducer: state => ({
    i18n: state.i18n
  })
});

const contentfulClient = createClient();

const store = createStore({
  state: {
    i18n: {},
    config: {},
    countdown: null,
    boardingCountdown: null,
    statusInterval: null,
    boardingStatusInterval: null,
    versionCheckInterval: null,
    offline: true,
    version: null,
    customAppState: null
  },
  getters: {
    appState: state => {
      if (state.customAppState) {
        return state.customAppState;
      }

      if (state.countdown > 5) {
        return "StateCountdown";
      } else if (state.countdown > -1) {
        return "StateBoarding";
      }

      return "StateWaiting";
    },
    formattedCountdown(state) {
      if (state.countdown > 5) {
        return `${state.countdown}'`;
      }

      const countdown = Math.max(0, state.boardingCountdown);
      const minutes = Math.floor(countdown / 60);
      const seconds = countdown - minutes * 60;
      const formatted = `${minutes}:${seconds < 10 ? `0` : ""}${seconds}`;

      return formatted;
    }
  },
  mutations: {
    setI18n(state, payload) {
      state.i18n = payload;
    },
    setConfig(state, payload) {
      state.config = payload;
    },
    setOffline(state, payload) {
      state.offline = payload;
    },
    setCountdown(state, payload) {
      state.countdown = payload;
    },
    setStatusInterval(state, { fn, delay }) {
      state.statusInterval = setInterval(fn, delay);
    },
    resetStatusInterval(state) {
      state.statusInterval = clearInterval(state.statusInterval);
    },
    setBoardingStatusInterval(state, { fn, delay }) {
      state.boardingStatusInterval = setInterval(fn, delay);
    },
    resetBoardingStatusInterval(state) {
      state.boardingStatusInterval = clearInterval(
        state.boardingStatusInterval
      );
    },
    setVersion(state, payload) {
      state.version = payload;
    },
    setVersionCheckInterval(state, { fn, delay }) {
      state.versionCheckInterval = setInterval(fn, delay);
    },
    stopVersionCheckInterval(state) {
      state.versionCheckInterval = clearInterval(state.versionCheckInterval);
    },
    setBoardingCountdown(state, payload) {
      state.boardingCountdown = payload;
    },
    setCustomAppState(state, payload) {
      state.customAppState = payload;
    }
  },
  actions: {
    async initialiseStore(context) {
      context.commit("setOffline", false);

      const getI18n = () =>
        contentfulClient.getEntries({
          content_type: "i18n",
          locale: "*"
        });

      // Load data from Contentful and local config file
      const [i18nData, config] = await Promise.all([getI18n(), getConfig()]);

      // Set up translations
      const i18n = i18nData.items.reduce((acc, item) => {
        const { key, value } = item.fields;

        acc[key[VUE_APP_CONTENTFUL_DEFAULT_LANGUAGE]] = value;

        return acc;
      }, {});

      // Persist
      context.commit("setI18n", i18n);
      context.commit("setConfig", config);

      // Fetch status
      this.dispatch("initStatusUpdate");

      // Fetch version to decide whether we need to reload
      this.dispatch("initVersionCheck");
    },
    async initStatusUpdate(context, { skipInitial } = {}) {
      // Skip initial update when restarting to slow down possible loop
      if (!skipInitial) {
        context.dispatch("statusUpdate");
      }

      // Poll API every 5 seconds
      if (!this.state.statusInterval) {
        context.commit("setStatusInterval", {
          fn: () => {
            context.dispatch("statusUpdate");
          },
          delay: 5000
        });
      }
    },
    async statusUpdate(context) {
      const { EtaVipCarrierRemoteStation } = await getCountdown(
        this.state.config.baseUrl
      );

      context.commit("setCountdown", EtaVipCarrierRemoteStation);

      // Stop polling API when reaching 5 mins
      if (EtaVipCarrierRemoteStation <= 5) {
        context.dispatch("stopStatusUpdate");

        // Start custom boarding countdown
        context.commit("setBoardingCountdown", this.state.countdown * 60);

        if (!this.state.boardingStatusInterval) {
          context.commit("setBoardingStatusInterval", {
            fn: () => {
              const boardingCountdown = this.state.boardingCountdown - 1;

              context.commit("setBoardingCountdown", boardingCountdown);

              if (boardingCountdown <= 0) {
                // Stop updating custom boarding countdown
                context.dispatch("stopBoardingStatusUpdate");

                // Restart polling API
                context.dispatch("initStatusUpdate", { skipInitial: true });
              }
            },
            delay: 1000
          });
        }
      }
    },
    stopStatusUpdate(context) {
      context.commit("resetStatusInterval");
    },
    stopBoardingStatusUpdate(context) {
      context.commit("resetBoardingStatusInterval");
    },
    initVersionCheck(context) {
      // Check every minute whether the build version changed
      // See watcher in `main.js`
      context.commit("setVersionCheckInterval", {
        fn: async () => {
          const version = await getVersion();

          context.commit("setVersion", version);
        },
        delay: 60000
      });
    },
    stopVersionCheck(context) {
      context.commit("resetVersionCheckInterval");
    }
  },
  plugins: [vuexLocal.plugin]
});

export default store;
