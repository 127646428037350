<template>
  <div class="viewport">
    <VideoBackground />

    <img :src="logo" alt="Logo" class="logo" />

    <div class="content">
      <Spinner :appState="appState" />
      <StateCountdown />
      <StateBoarding />
      <StateWaiting />
    </div>
  </div>
</template>

<script>
import VideoBackground from "@/components/VideoBackground.vue";
import Spinner from "@/components/Spinner.vue";
import StateCountdown from "@/components/states/StateCountdown.vue";
import StateBoarding from "@/components/states/StateBoarding.vue";
import StateWaiting from "@/components/states/StateWaiting.vue";
import logo from "@/assets/media/logo.png";
import gong from "@/assets/media/gong.wav";

export default {
  name: "Page",
  components: {
    VideoBackground,
    Spinner,
    StateCountdown,
    StateBoarding,
    StateWaiting
  },
  data() {
    return {
      logo,
      isPlayingAudio: false
    };
  },
  computed: {
    appState() {
      return this.$store.getters.appState;
    }
  },
  watch: {
    /**
     * Play sound when changing from countdown to boarding
     */
    appState(newValue, oldValue) {
      if (
        oldValue === "StateCountdown" &&
        newValue === "StateBoarding" &&
        !this.isPlayingAudio
      ) {
        this.playGong();
      }
    }
  },
  mounted() {
    this.$options.keyEventListener = document.addEventListener(
      "keyup",
      this.onKeyPressed
    );

    this.gong = new Audio(gong);
  },
  beforeUnmount() {
    document.removeEventListener("keyup", this.$options.keyEventListener);
  },

  methods: {
    onKeyPressed(e) {
      if (e.code === "Digit1") {
        this.$store.commit("setCustomAppState", "StateCountdown");
      } else if (e.code === "Digit2") {
        this.$store.commit("setCustomAppState", "StateBoarding");
      } else if (e.code === "Digit3") {
        this.$store.commit("setCustomAppState", "StateWaiting");
      } else if (e.code === "Digit4") {
        this.$store.commit("setCustomAppState", null);
      }
    },

    /**
     * Boiiiiiing
     */
    playGong() {
      this.isPlayingAudio = true;
      this.gong.play();

      // Keep the gong silent for a while so it doesn't play too much in case
      // state switches to quickly
      setTimeout(() => {
        this.isPlayingAudio = false;
      }, 5000);
    }
  }
};
</script>

<style scoped>
@import url("../assets/css/variables.css");

.viewport {
  padding: var(--spacing-page);
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  height: 100vh;
  overflow: hidden;
}

.content {
  position: relative;
}

.logo {
  position: absolute;
  top: 60px;
  right: 60px;
  display: block;
  width: 90px;
  height: auto;
}
</style>
