<template>
  <div class="video">
    <video autoplay muted loop :src="src"></video>
  </div>
</template>

<script>
import src from "@/assets/media/background.mp4";

export default {
  name: "VideoBackground",
  data() {
    return { src };
  }
};
</script>

<style scoped>
@import url("../assets/css/variables.css");

.video {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
}
.video::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: var(--color-background);
  opacity: 0.8;
}

video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
</style>
