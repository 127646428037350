<template>
  <transition :css="false" @enter="animateEnter" @leave="animateLeave">
    <div
      v-if="$store.getters.appState === 'StateWaiting'"
      class="state state--StateWaiting"
    >
      <img :src="logo" alt="Logo" class="logo" />
    </div>
  </transition>
</template>

<script>
import gsap from "gsap";
import logo from "@/assets/media/logo-vip.svg";

export default {
  name: "StateBoarding",
  data() {
    return { logo };
  },
  methods: {
    animateEnter(el, done) {
      gsap.fromTo(
        el,
        { opacity: 0 },
        {
          opacity: 1,
          duration: 0.5,
          delay: 0,
          ease: "power2.inOut",
          onComplete: done
        }
      );
    },
    animateLeave(el, done) {
      gsap.fromTo(
        el,
        { opacity: 1 },
        {
          opacity: 0,
          duration: 0.5,
          delay: 0,
          ease: "power2.inOut",
          onComplete: done
        }
      );
    }
  }
};
</script>

<style scoped>
@import url("../../assets/css/variables.css");

.state {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.logo {
  width: 356px;
  height: auto;
  display: block;
}
</style>
