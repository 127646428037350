import { createClient as createContentfulClient } from "contentful";

const {
  VUE_APP_CONTENTFUL_SPACE_ID,
  VUE_APP_CONTENTFUL_ACCESS_TOKEN,
  VUE_APP_STATUS_API
} = process.env;

const config = {
  space: VUE_APP_CONTENTFUL_SPACE_ID,
  accessToken: VUE_APP_CONTENTFUL_ACCESS_TOKEN
};

/**
 * Create contentful client, catch error (allowing for an offline fallback)
 * @returns {Object}
 */
function createClient() {
  try {
    return createContentfulClient(config);
  } catch (err) {
    return null;
  }
}

/**
 * Get translated field value, fall back to default language
 * @param {Object} field Contentful field
 * @param {String} [language]
 * @returns {String}
 */
function t(field = {}, language) {
  return (
    field[language] || field[process.env.VUE_APP_CONTENTFUL_DEFAULT_LANGUAGE]
  );
}

/**
 * Read status from API
 * @returns {Object}
 */
async function getCountdown(baseUrl) {
  const response = await fetch(`${baseUrl}/${VUE_APP_STATUS_API}`);
  const data = await response.json();

  return data;
}

/**
 * Read config from local config.json
 * @returns {Object}
 */
async function getConfig() {
  const response = await fetch("/config.json");
  const data = await response.json();

  return data;
}

/**
 * Read version from local build-hash.txt
 * @returns {String}
 */
async function getVersion() {
  const timestamp = new Date().getTime();
  const response = await fetch(`/build-hash.txt?${timestamp}`);
  const version = await response.text();

  return version.trim();
}

export { createClient, t, getCountdown, getConfig, getVersion };
